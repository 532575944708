<template>
  <div class="download">
    <div class="download__main">
      <template v-if="!downloadLink && !error">
        <div class="alert">
          <h1 class="alert__title" v-html="$t('UPLOAD_BOX.WAIT')"></h1>
        </div>
      </template>
      <template v-if="downloadLink && !error">
        <p class="download__text">
            <span v-html="$t('UPLOAD_BOX.SCAN')"></span>
        </p>
        <div class="download__qrcode">
          <vue-qrcode :value="downloadLink" :scale="4"></vue-qrcode>
        </div>
      </template>
      <template :role="div" class="alert" v-if="error">
        <div class="alert">
          <h1 class="alert__title">{{ error }}</h1>
          <button class="alert__button alert__button--confirm" type="button" @click="emitRetryButton">{{ $t('UPLOAD_BOX.RETRY') }}</button>
          <button class="alert__button" type="button" @click="emitGoToHome">{{ $t('UPLOAD_BOX.GO_TO_HOME') }}</button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
export default {
  name: "UploadBox",
  components: {
    VueQrcode,
  },
  props: {
    error: {
      default: null,
      type: String
    },
    downloadLink: {
      default: "",
      type: String
    }
  },
  methods: {
    emitButtonClicked() {
      this.$emit("buttonClicked");
    },
    emitRetryButton() {
      this.$emit("retryButtonClicked");
    },
    emitGoToHome() {
      this.$emit('goHome');
    }
  },
};
</script>

<style lang="scss" scoped>
.download {
    padding: 0;
    &__title {
        width: 10.416vw;
        margin: 0 auto;
    }
    &__main {
        font-family: 'simplon_monomedium';
        padding: 2.77vh 0;
        border-bottom: 0.238vh dotted #000;
        width: 16.9vw;
        
        strong {
            font-family: 'simplon_monobold';
        }
    }
    &__text {
        font-size: 1.988vh;
        img {
            height: 3.703vh;
            float: left;
            margin-right: 1.041vw;
            &:after {
                clear: both;
            }
        }
    }
    &__qrcode {
        margin: 3.703vh auto 0;
        text-align: center;
        img {
          width: 75%;
        }
    }
}

.alert {
  text-align: center;
  font-family: 'simplon_monoregular', sans-serif;
  text-transform: uppercase;
  padding: 6vh 3.51vw 4.25vh 3.51vw;
  &__title {
      font-size: 2.22vh;
      font-family: 'simplon_monomedium', sans-serif;
      margin-bottom: 4.62vh;
  }
  &__subtitle {
      font-size: 2.22vh;
      margin-bottom: 4.62vh;
  }
  &__button {
      margin: 0 0.572vw;
      min-width: 5.98vw;
      height: 4.25vh;
      font-family: 'simplon_monoregular', sans-serif;
      font-size: 2vh;
      text-transform: uppercase;
      background-color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: .5vw;
      border: 0.052vw solid black;
      color: #000;
      &--confirm{
          color: #fff;
          background-color: #000;
      }
  }
}
</style>
