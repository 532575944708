<template>
  <div :ref="'history-handle'" class="HistoryControls" 
    :class="{ 
      'HistoryControls--dragging' : shadowActive,
    }"
    
  >
    <img
      class="HistoryControls__Button"
      src="@/assets/img/ui/icn_undo.svg"
      @click="onUndoButtonClick"
    />
    <img
      class="HistoryControls__Button"
      src="@/assets/img/ui/icn_redo.svg"
      @click="onRedoButtonClick"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Draggable } from 'draggable-vue-directive';

export default {
  name: "HistoryControls",
  inheritAttrs: true,
  directives: {
    Draggable,
  },
  data() {
    return {
      shadowActive: false,
      draggableValue: {
        boundingElement: undefined,
        onDragStart: undefined,
        onDragEnd: undefined,
        initialPosition: undefined,
        resetInitialPos: undefined,
      }
    }
  },
  mounted() {
    this.draggableValue.boundingElement = this.$parent.$refs.c || undefined;
    this.draggableValue.onDragStart = this.onShadow;
    this.draggableValue.onDragEnd = this.offShadow;
  },
  methods: {
    ...mapActions("canvas", ["undo", "redo"]),
    onUndoButtonClick() {
      this.undo();
    },
    onRedoButtonClick() {
      this.redo();
    },
    onShadow() {
      this.shadowActive = true;
    },
    offShadow() {
      this.shadowActive = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.HistoryControls {
  position: absolute;
  display: flex;
  align-items: center;
  top: 20vh;
  left: calc(100% - 8vw);
  z-index: 10;
  border-radius: 1vh;
  padding: 0.75vh;
  box-shadow: 0 0 5px rgba(95, 70, 70, 0.5), inset 0 0 0 2px rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.8);

  &--dragging {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }

  &__container {
    position: relative;
  }

  &__Button {
    border-right: 1px solid black;
    cursor: pointer;
    width: 4vh;
    height: auto;
    padding: 0 0.7vh 0 0.7vh;
    position: relative;

    &:nth-last-of-type(1) {
      border-right: none;
      padding: 0 0.7vh 0 0.7vh;
    }
  }
}
</style>