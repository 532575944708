import { fabric } from 'fabric'

export const setActiveToolOnCanvas = (tool, canvas) => {
  if (tool && tool.type === "brush") {
    let brushObj = {};

    // this.setCanvasDrawingMode(true);

    if (tool.id === "lightMarker") {
      brushObj.type = new fabric.PencilBrush(canvas);
      brushObj.width = 8;
    }

    if (tool.id === "strongMarker") {
      brushObj.type = new fabric.PencilBrush(canvas);
      brushObj.width = 15;
    }

    if (tool.id === "eraser") {
      brushObj.type = new fabric.EraserBrush(canvas);
      brushObj.width = 24;
    }

    if (tool.id === "highlighter") {
      brushObj.type = new fabric.PencilBrush(canvas);
      brushObj.width = 15;
    }

    if (tool.id === "smudge") {
      // brushObj.type = new fabric.SprayBrush(canvas);
      // brushObj.type.density = 15
      // brushObj.type.dotWidth = 2
      // brushObj.type.dotWidthVariance = 6
      // brushObj.width = 24;
      // brushObj.color = 'rgba(78,69,51,0.1)';
      brushObj.type = new fabric.PencilBrush(canvas);
      brushObj.width = 20;
      brushObj.color = 'rgba(78,69,51,0.15)';
      brushObj.type.shadow = new fabric.Shadow({
        blur: 6,
        offsetX: 0,
        offsetY: 0,
        affectStroke: false,
        color: '#000000',
        opacity: 1
      });
    }

    // Set color as previous color
    if (canvas.freeDrawingBrush.color && ['lightMarker','strongMarker','highlighter'].includes(tool.id)) {
      brushObj.color = canvas.freeDrawingBrush.color
    }

    // Check if a texture brush was selected
    // if (canvas.freeDrawingBrush.source instanceof Image) {
    //   brushObj.type = canvas.freeDrawingBrush
    // }

    


    // this.setBrush(brushObj);
    return brushObj
  }
}

export const loadActiveToolSwatches = tool => {
  let swatches = [
    {
      id: 'swatch-1',
      code: '#ff5252',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-2',
      code: '#2196f3',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-3',
      code: '#8bc34a',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-4',
      code: '#ffeb3b',//'#ff5733',
      type: 'color',
      isActive: false,
    },

    {
      id: 'swatch-5',
      code: '#f3a400',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-6',
      code: '#d11547',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-7',
      code: '#f2b5d4',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-8',
      code: '#f28482',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-9',
      code: '#af87ce',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-10',
      code: '#429ebd',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-11',
      code: '#346b6d',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-12',
      code: '#8cc872',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-13',
      code: '#004dff',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-14',
      code: '#84a59d',
      type: 'color',
      isActive: false,
    },
    {
      id: 'swatch-15',
      code: '#8fc8eb',
      type: 'color',
      isActive: false,
    },

    {
      id: 'glitter_1',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_2',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_3',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_4',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_5',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_6',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_7',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_8',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_9',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_10',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_11',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_12',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_13',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_14',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
    {
      id: 'glitter_15',
      // code: '#ffc300',
      type: 'texture',
      isActive: false,
    },
  ]

  if (tool.id === 'highlighter') {
    swatches = [
      {
        id: 'h-swatch-1',
        code: 'rgba(255, 106, 9, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-2',
        code: 'rgba(0, 206, 246, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-3',
        code: 'rgba(114, 241, 109, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-4',
        code: 'rgba(254, 252, 31, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-5',
        code: 'rgba(255, 77, 157, 0.7)',
        type: 'color',
        isActive: false,
      },


      {
        id: 'h-swatch-6',
        code: 'rgba(104, 125, 206, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-7',
        code: 'rgba(245, 193, 172, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-8',
        code: 'rgba(206, 229, 138, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-9',
        code: 'rgba(147, 207, 197, 0.7)',
        type: 'color',
        isActive: false,
      },
      {
        id: 'h-swatch-10',
        code: 'rgba(223, 238, 155, 0.7)',
        type: 'color',
        isActive: false,
      },
    ]
  }

  return swatches;
}

export const loadToolElements = () => {
  let elements = [
    {
      id: "borchia_1",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_2",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_3",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_4",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_5",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_6",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_7",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "borchia_8",
      isVisible: true,
      type: "borchie",
      ext: "svg",
    },
    {
      id: "brillante_1",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_2",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_3",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_4",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_5",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_6",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_7",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_8",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_9",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_10",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_11",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "brillante_12",
      isVisible: true,
      type: "brillanti",
      ext: "svg",
    },
    {
      id: "charm_1",
      isVisible: true,
      type: "charms",
      ext: "svg",
    },
    {
      id: "charm_2",
      isVisible: true,
      type: "charms",
      ext: "svg",
    },
    {
      id: "charm_3",
      isVisible: true,
      type: "charms",
      ext: "svg",
    },
    {
      id: "charm_4",
      isVisible: true,
      type: "charms",
      ext: "svg",
    },
    {
      id: "charm_5",
      isVisible: true,
      type: "charms",
      ext: "png",
    },
    {
      id: "charm_6",
      isVisible: true,
      type: "charms",
      ext: "png",
    },
    {
      id: "charm_7",
      isVisible: true,
      type: "charms",
      ext: "png",
    },
    {
      id: "charm_8",
      isVisible: true,
      type: "charms",
      ext: "png",
    },
    {
      id: "charm_9",
      isVisible: true,
      type: "charms",
      ext: "png",
    },
    {
      id: "sticker_1",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_2",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_3",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_4",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_5",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_6",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_7",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_8",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_9",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_10",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_11",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_12",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_13",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_14",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_15",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_16",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_17",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_18",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_19",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_20",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_21",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_22",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_23",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
    {
      id: "sticker_24",
      isVisible: true,
      type: "stickers",
      ext: "svg",
    },
  ]

  return elements;
}

export const getQuotes = () => {
  const quotes = [
    {
      id: 'quote1',
      style: 'golden_gooseregular',
      quote: 'GOLDEN HAPPENS',
    },
    {
      id: 'quote2',
      style: 'golden_gooseregular',
      quote: 'FAMILY',
    },
    {
      id: 'quote3',
      style: 'golden_gooseregular',
      quote: 'HAPPINESS',
    },
    {
      id: 'quote4',
      style: 'golden_gooseregular',
      quote: 'SMILE',
    },
    {
      id: 'quote5',
      style: 'golden_gooseregular',
      quote: 'THANK YOU',
    },
    {
      id: 'quote6',
      style: 'golden_gooseregular',
      quote: 'LOVE LOVE LOVE',
    },
    {
      id: 'quote7',
      style: 'golden_gooseregular',
      quote: 'LOVE FROM VENICE',
    },
    {
      id: 'quote8',
      style: 'golden_gooseregular',
      quote: 'DREAMED IN VENICE',
    },
    {
      id: 'quote9',
      style: 'golden_gooseregular',
      quote: 'WE LOVE',
    },
    {
      id: 'quote10',
      style: 'golden_gooseregular',
      quote: 'LOVE TO YOU AND ALL YOUR LOVES',
    },
    {
      id: 'quote11',
      style: 'golden_gooseregular',
      quote: 'TOGETHER WE ARE STRONGER',
    },
    {
      id: 'quote12',
      style: 'golden_gooseregular',
      quote: 'FOR DREAMERS ONLY',
    },
  ]
  return quotes;
}

export const deleteButton = () => {
  fabric.Object.prototype.controls.deleteControl = fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -17,
    offsetX: 17,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIcon,
    cornerSize: 24
  });

  fabric.Object.prototype.transparentCorners = true;
  fabric.Object.prototype.cornerColor = 'gray';
  fabric.Object.prototype.cornerSize = 8;
  fabric.Object.prototype.borderColor = 'gray';
  fabric.Object.prototype.borderDashArray = [5];
};

const deleteObject = (event, transform) => {
  var target = transform.target;
  var canvas = target.canvas;
      canvas.remove(target);
      canvas.requestRenderAll();
};

const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
  var deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23000000;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
  var img = document.createElement('img');
  img.src = deleteIcon; //require(`@/assets/img/ui/delete.svg`);
  
  var size = 20;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size/2, -size/2, size, size);
  ctx.restore();
};