<template>
  <div id="app" ref="app" class="dreamwall">
    <aside class="dreamwall__side">
      <articles-list @selectArticle="onSelectedArticle" />
    </aside>
    <main class="dreamwall__main">
      <button @click="savePng" class="dreamwall__download">
        <img src="@/assets/img/ui/btn_download.svg" />
        {{ $t('APP.DOWNLOAD') }}
      </button>
      <draggable-window :name="'default'" style="position: absolute; top: 35%; left: 5%">
        <template v-slot:icon>
          <img src="@/assets/img/ui/tools/icn_resize_pens_tool.svg" />
        </template>
        <tools-sidebar type="default" />
        <color-bar
          v-if="activeTool && 
            ['bucket', 'strongMarker', 'lightMarker', 'highlighter'].includes(
              activeTool.id
            )
          "
        />
      </draggable-window>
      <draggable-window
        style="position: absolute; top: 75%; left: 5%"
        :name="'group2'" 
      >
        <template v-slot:icon>
          <img src="@/assets/img/ui/tools/icn_stickers.svg" />
        </template>
        <tools-sidebar type="group2" />
        <elements-sidebar
          @elementClick="onElementClick($event, activeTool.id)"
          :filterByType="activeTool.id"
          :itemsPerRow="4"
          v-if="activeTool && ['stickers'].includes(activeTool.id)"
        />
      </draggable-window>
      <draggable-window
        style="position: absolute; top: 55%; left: 5%"
        :name="'group3'" 
      >
        <template v-slot:icon>
          <img src="@/assets/img/ui/tools/icn_brillanti.svg" />
        </template>
        <tools-sidebar type="group3" />
        <elements-sidebar
          @elementClick="onElementClick($event, activeTool.id)"
          :filterByType="activeTool.id"
          :itemsPerRow="6"
          v-if="activeTool && ['charms', 'brillanti', 'borchie'].includes(activeTool.id)"
        />
      </draggable-window>
      <history-controls></history-controls>
      <canvas ref="c" />
      <button class="dreamwall__ghost-button" @click="returnToHome()">Go to Home</button>
    </main>
    <modal name="colorWheel"></modal>
      <modal name="handWriting"></modal>
      <modal name="download">
        <upload-box
          ref="uploadBox"
          :error="error"
          :downloadLink="downloadLink"
          @retryButtonClicked="onUploadBoxRetry"
          @goHome="goToHome"
        ></upload-box>
      </modal>
      <modal
        name="reset"
        :param="article"
        @setArticle="onCorfirmArticle"
      ></modal>
      <language v-if="devMode"></language>
  </div>
</template>

<script>
import { fabric } from "fabric";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import IdleVue from "idle-vue";

import {
  setActiveToolOnCanvas,
  loadActiveToolSwatches,
  deleteButton,
} from "@/utils";
import trimCanvas from "trim-canvas";
import ToolsSidebar from "@/components/ToolsSidebar.vue";
import ElementsSidebar from "@/components/ElementsSidebar.vue";
import ColorBar from "@/components/ColorBar.vue";
import HistoryControls from "@/components/HistoryControls.vue";
import DraggableWindow from "@/components/DraggableWindow.vue";
import Modal from "@/components/Modal.vue";
import ArticlesList from "@/components/ArticlesList.vue";
import UploadBox from "./components/UploadBox.vue";
import Language from "./components/Language.vue";

const eventsHub = new Vue();
const options = {
  eventEmitter: eventsHub,
  idleTime: 60 * 5 * 1000,
  startAtIdle: false,
  events: ["mousemove", "keydown", "mousedown", "touchstart", "touchmove"],
};

Vue.use(IdleVue, options);

export default {
  name: "App",
  components: {
    ToolsSidebar,
    ElementsSidebar,
    ColorBar,
    HistoryControls,
    DraggableWindow,
    Modal,
    ArticlesList,
    UploadBox,
    Language,
  },
  onIdle() {
    this.goToHome();
  },
  data() {
    return {
      pattern: {},
      draggedElement: null,
      selectedBrush: "vline",
      color: null,
      draggableValue: {
        boundingElement: undefined,
      },
      article: null,
      minScaleX: 2,
      minScaleY: 2,
      error: null,
      downloadLink: "",
      devMode: null,
      count: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  mounted() {

    this.draggableValue.boundingElement = this.$refs.app;
    this.setCanvas(
      new fabric.Canvas(this.$refs.c, {
        hoverCursor: "pointer",
        targetFindTolerance: 2,
        isDrawingMode: true,
        selection: false,
        // Maybe we can remove object stacking to let stickers always be on front
        preserveObjectStacking: false, // Needed to prevent object to be brought to front when selected
        // Add renderOnAddRemove for testing purpose
        // renderOnAddRemove: false,
        desynchronized: true,
        preserveDrawingBuffer: true,
      })
    );
    this.canvas.setWidth(window.innerWidth * (1 - 0.226));
    this.canvas.setHeight(window.innerHeight);

    this.initCanvas();

    this.canvas.on("selection:created", (evt) => {
      // Disable group selection
      if (evt.target.type === "activeSelection") {
        this.canvas.discardActiveObject();
      }

      if (this.canvas.isDrawingMode === true) {
        this.setCanvasDrawingMode(false);
      }

      setTimeout(() => {
        this.canvas.requestRenderAll();
      }, 0);

      if(process.env.NODE_ENV !== 'production') console.log("@CANVAS SELECTION:CREATED", JSON.stringify(evt));
    });

    this.devMode = process.env.NODE_ENV !== 'production';

    deleteButton();

    this.canvas.on("dragenter", this.onCanvasDragEnter, false);
    this.canvas.on("drop", this.onDrop, false);

    this.canvas.on("path:created", (e) => {
      e.path.lockMovementX = true;
      e.path.lockMovementY = true;
      e.path.hasControls = false;
      e.path.selectable = false;
      e.path.evented = false;

      // Hack to move drawings below the sneaker and keep stickers above
      e.path.moveTo(
        this.canvas
          .getObjects()
          .indexOf(
            this.canvas.getObjects().find((object) => object.id === "sneaker")
          )
      );

      this.saveCanvasState();
      this.canvas.requestRenderAll();

      if(process.env.NODE_ENV !== 'production') console.log("@CANVAS PATH:CREATED", JSON.stringify(e));
    });

    this.canvas.on("object:moving", function(e) {
      var obj = e.target;
      // if object is too big ignore
      if (
        obj.currentHeight > obj.canvas.height ||
        obj.currentWidth > obj.canvas.width
      ) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (
        obj.getBoundingRect().top + obj.getBoundingRect().height >
          obj.canvas.height ||
        obj.getBoundingRect().left + obj.getBoundingRect().width >
          obj.canvas.width
      ) {
        obj.top = Math.min(
          obj.top,
          obj.canvas.height -
            obj.getBoundingRect().height +
            obj.top -
            obj.getBoundingRect().top
        );
        obj.left = Math.min(
          obj.left,
          obj.canvas.width -
            obj.getBoundingRect().width +
            obj.left -
            obj.getBoundingRect().left
        );
      }
    });

    //If an object has been modified at all, save the new state
    this.canvas.on("object:modified", (object) => {
      this.saveCanvasState();
      setTimeout(() => {
        this.canvas.requestRenderAll();
      }, 0);

      if(process.env.NODE_ENV !== 'production') console.log("@CANVAS OBJECT:MODIFIED (requestRenderAll)", JSON.stringify(object));
    });

    //If an object has been added (i.e. sticker), save the new state
    this.canvas.on("object:added", (event) => {
      // if (event.target.id !== "sneaker") {
      // Save canvas state if a non-path object is added and if it's not the sneaker
      if (
        !event.target.path &&
        event.target.id !== "sneaker" &&
        event.target.type !== "group" &&
        event.target.id !== "areas"
      ) {
        this.saveCanvasState();
      }

      if (event.target.type !== "path") {
        // this.canvas.renderAll();
        setTimeout(() => {
          this.canvas.requestRenderAll();
        }, 0);
        if(process.env.NODE_ENV !== 'production') console.log("@CANVAS OBJECT:ADDED (renderAll)", JSON.stringify(event));
      }
    });

    this.canvas.on("mouse:up", () => {
      // Add settimeout to avoid flicker
      if (this.canvas.isDrawingMode === true) {
        setTimeout(() => {
          this.canvas.renderAll();
        }, 0);
      }

      if(process.env.NODE_ENV !== 'production') console.log("@CANVAS MOUSE:UP (renderAll)");
    });

    // Handler to create a selection in drawing mode
    this.canvas.on("mouse:down:before", (event) => {
      if(process.env.NODE_ENV !== 'production') console.log("@CANVAS MOUSE:DOWN:BEFORE", JSON.stringify(event));

      if (this.canvas.isDrawingMode === true) {
        const pointer = event.pointer;
        const target = this.canvas._searchPossibleTargets(
          this.canvas.getObjects(),
          pointer
        );

        if (target && target.type !== "path") {
          if(process.env.NODE_ENV !== 'production') console.log("TARGET FOUND, SWITCH MODE");
          this.setActiveTool({});

          this.canvas.setActiveObject(target);
          // this.setCanvasDrawingMode(false);
          } else if (
          target &&
          target.type === "path" &&
          this.activeTool.id === "bucket"
        ) {
          this.setCanvasDrawingMode(false);
        } else {
          if(process.env.NODE_ENV !== 'production') console.log("TARGET NOT FOUND, CONTINUE DRAWING..");
          // canvas.discardActiveObject().requestRenderAll()
        }
      }
    });

    this.canvas.on("mouse:down", (event) => {
      if(process.env.NODE_ENV !== 'production') console.log("@CANVAS MOUSE:DOWN", JSON.stringify(event));
      if (event.target && this.activeTool && this.activeTool.id === "bucket") {
        this.setFillArea(event.target);
      }
    });

    // this.canvas.on("touch:gesture", (event) => {
    //   console.log("@CANVAS TOUCH:GESTURE");
    //   event.preventDefault();
    // });
  },
  computed: {
    ...mapGetters("canvas", ["canvas"]),
    ...mapGetters("ui", [
      "tools",
      "activeTool",
      "swatches",
      "activeSwatch",
      "modals",
      "articles",
      "activeArticle",
    ]),
  },
  methods: {
    ...mapActions("canvas", [
      "setCanvas",
      "setCanvasDrawingMode",
      "saveCanvasState",
      "setBrush",
      "setBrushColor",
      "resetHistoryState",
    ]),
    ...mapActions("ui", [
      "setActiveSwatch",
      "setActiveTool",
      "setSwatches",
      "setActiveModal",
      "setActiveArticle",
      "setActiveGroup",
    ]),
    initCanvas() {
      // Brush and color init for component and canvas
      this.setActiveTool(this.tools[0].id);
      this.setBrush(setActiveToolOnCanvas(this.tools[0], this.canvas));

      const activeToolSwatches = loadActiveToolSwatches(this.tools[0]);
      this.setSwatches(activeToolSwatches);

      this.setActiveSwatch(activeToolSwatches[0]);
      this.setBrushColor(activeToolSwatches[0].code);

      if (!this.activeArticle) this.setActiveArticle(this.articles[0].id);

      this.loadSneakerSVG();
      this.loadSneakerPNG();
    },
    returnToHome() {
      this.count = this.count + 1;
      setTimeout(() => {
        this.count = 0;
      }, 3000);
      if(this.count >= 3) this.goToHome();
    },
    setFillArea(activeObject) {
      if (activeObject.id !== "outline" && this.activeSwatch.id) {
        if (this.activeSwatch.type === "texture") {
          const img = new Image();
          img.src = require(`@/assets/img/${this.activeSwatch.id}.png`);
          fabric.util.loadImage(img.src, function(img) {
            activeObject.set(
              "fill",
              new fabric.Pattern({ source: img }, null, {
                crossOrigin: "Anonymous",
              })
            );
            img.remove();
          });
        } else {
          activeObject.set("fill", this.activeSwatch.code);
        }

        this.canvas.fire("object:modified", { target: activeObject });
      }
    },
    onSelectedArticle(data) {
      this.setActiveModal("reset");
      this.article = data.item.id;
    },
    goToHome() {
      this.setActiveModal({});
      this.setActiveGroup('default');
      this.resetHistoryState();
      this.canvas.clear();
      this.$router.push("/");
    },
    onCorfirmArticle(data) {
      this.article = null;
      this.resetHistoryState();
      this.canvas.clear();
      this.setActiveArticle(data);
      this.initCanvas();
    },
    setCanvasBackground() {
      // this.canvas.setBackgroundImage(
      //   "bg.jpg",
      //   this.canvas.renderAll.bind(this.canvas),
      //   {
      //     width: this.canvas.width,
      //     height: this.canvas.height,
      //     originX: "left",
      //     originY: "top",
      //   }
      // );
    },
    loadSneakerPNG() {
      const img = new Image();
      img.src = require(`@/assets/img/articles/png/${this.activeArticle.id}.png`);
      fabric.Image.fromURL(img.src, (image) => {
        image.lockMovementX = true;
        image.lockMovementY = true;
        image.hasControls = false;
        image.selectable = false;
        image.evented = false;
        image.erasable = false;
        image.id = "sneaker";

        // Scaling image proportionally to canvas width
        image.scaleToWidth(this.canvas.width * 0.759);
        // this.canvas.setOverlayImage(image);
        this.canvas.add(image);
        image.bringToFront();
        image.center();

        // this.canvas.renderAll();
        this.canvas.requestRenderAll();
        this.saveCanvasState();
        img.remove();
      });
    },
    loadSneakerSVG() {
      var group = [];
      // @TODO: need to find a way to load the parts as centered. See "group / ungroup" on FabricJS
      fabric.loadSVGFromURL(
        "sneakerAreas2.svg",
        () => {
          // var obj;
          // for (var i = 0; i < objects.length; i++) {
          //   obj = objects[i];
          //   obj.perPixelTargetFind = false;
          //   obj.fill = "transparent";
          //   console.log("OBJ IN LOADING", obj);
          //   obj.selectable = false;
          //   obj.lockMovementX = true;
          //   obj.lockMovementY = true;
          //   obj.evented = true;
          //   obj.hasControls = false;
          //   obj.erasable = false;

          //   // if (obj.id === "strap") {
          //   //   this.canvas.bringToFront(obj);
          //   // }

          //   this.canvas.add(obj);
          // }

          var loadedObjects = new fabric.Group(group);
          loadedObjects.scaleToWidth(this.canvas.width * 0.629);
          this.canvas.add(loadedObjects);
          loadedObjects.center();
          var items = loadedObjects._objects;
          loadedObjects._restoreObjectsState();
          loadedObjects.id = "areas";
          this.canvas.remove(loadedObjects);
          items.forEach((item) => this.canvas.add(item));
          this.canvas.renderAll();

          // Activate ClipPath on canvas
          // Needs an svg of the clipped area (we don't have it, yet)
          // const grp = fabric.util.groupSVGElements(objects, options);
          // this.canvas.clipPath = grp;
        },
        function(item, object) {
          object.set({
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            erasable: false,
            perPixelTargetFind: true,
            fill: "rgba(255, 255, 255, 0.01)",
            evented: item.id !== "outline",
          });
          group.push(object);
        }
      );

      // fabric.loadSVGFromURL("sneakerOutline.svg", (objects, options) => {
      //   const groupedObj = fabric.util.groupSVGElements(objects, options);
      //   groupedObj.lockMovementX = true;
      //   groupedObj.lockMovementY = true;
      //   groupedObj.hasControls = false;
      //   groupedObj.selectable = false;
      //   groupedObj.evented = false;
      //   console.log("LOAD OUTLINE", groupedObj);
      //   this.canvas.setOverlayImage(groupedObj);
      //   groupedObj.center();

      //   this.canvas.setBackgroundImage(
      //     "bg.jpg",
      //     () => {
      //       this.canvas.renderAll();
      //       // Saving initial canvas state after importing sneaker file
      //       this.saveCanvasState();
      //     },
      //     {
      //       width: this.canvas.width,
      //       height: this.canvas.height,
      //       originX: "left",
      //       originY: "top",
      //     }
      //   );
      // });
    },
    onCanvasDragEnter(event) {
      if(process.env.NODE_ENV !== 'production') console.log("ON CANVAS DRAG ENTER", event);
      // event.e.target.style.background = "red";
    },
    onDrop(event) {
      event.e.preventDefault();
      if(process.env.NODE_ENV !== 'production') console.log("ON DROP", event);
      // console.log("DRAGGED ELEMENT WHEN DROPPED", this.draggedElement);

      // const newImage = new fabric.Image(this.draggedElement, {
      //   // scaleX: 0.15,
      //   // scaleY: 0.15,
      //   left: 100, // Can somehow take the drop point, if we extend the canvas to full screen (i.e. event.e.layerX)
      //   top: 100,
      // });
      // newImage.scaleToWidth(200);
      // console.log("NEW IMAGE", newImage);
      // this.canvas.add(newImage);
    },
    onDragStart(event) {
      this.draggedElement = event.target;
      if(process.env.NODE_ENV !== 'production') console.log("THIS DRAGGED ELEMENT", this.draggedElement);
    },
    onElementClick(event, type) {
      if(process.env.NODE_ENV !== 'production') console.log("@STICKER CLICK", JSON.stringify(event));
      //this.setActiveTool({});

      // const newStickerInstance = new fabric.Image(event.target, {
      //   scaleX: 0.5,
      //   scaleY: 0.5,
      //   left: this.canvas.width * 0.8,
      //   top: this.canvas.height / 2,
      //   perPixelTargetFind: true,
      //   objectCaching: false,
      //   erasable: false
      // }, { crossOrigin: 'Anonymous' });

      if (event.target.src.match(/\.[0-9a-z]{1,5}$/i)[0] === ".png") {
        
        let newElementInstance = new fabric.Image(
          event.target,
          {
            scaleX: 0.025,
            scaleY: 0.025,
            left: this.canvas.width / 2 - event.target.width / 2,
            top: this.canvas.height / 2 - event.target.height / 2,
            perPixelTargetFind: true,
            objectCaching: false,
            erasable: false,
            minScaleLimit: 0.01,
            lockScalingFlip: true,
          },
          { crossOrigin: "Anonymous" }
        );

        var shadow = {
          color: "rgba(0,0,0,0.8)",
          blur: 35,
          offsetX: 10,
          offsetY: 10,
        };

        newElementInstance.setControlsVisibility({
          mb: false,
          ml: false,
          mr: false,
          mt: false,
        });

        if (type === "charms")
          newElementInstance.set("shadow", new fabric.Shadow(shadow));

        this.canvas.add(newElementInstance);
        this.canvas.setActiveObject(newElementInstance);
        this.canvas.requestRenderAll();
      } else {
        const svgGroup = [];
        fabric.loadSVGFromURL(
          event.target.src,
          () => {
            var loadedObjects = new fabric.Group(svgGroup);
            loadedObjects.set({
              scaleX: 0.5,
              scaleY: 0.5,
              left: this.canvas.width / 2 - loadedObjects.width / 4,
              top: this.canvas.height / 2 - loadedObjects.height / 4,
              perPixelTargetFind: true,
              objectCaching: true,
              erasable: false,
              minScaleLimit: 0.15,
              lockScalingFlip: true,
            });

            // Remove controls that would allow non uniform scaling
            loadedObjects.setControlsVisibility({
              mb: false,
              ml: false,
              mr: false,
              mt: false,
            });

            this.canvas.add(loadedObjects);
            this.canvas.setActiveObject(loadedObjects);
            this.canvas.requestRenderAll();
          },
          function(item, object) {
            object.erasable = false;
            svgGroup.push(object);
          }
        );
      }

      // newStickerInstance.center().setCoords();
      this.setCanvasDrawingMode(false);
    },
    onWindowResize() {
      this.canvas.setWidth(window.innerWidth * (1 - 0.226));
      this.canvas.setHeight(window.innerHeight);
      this.setCanvasBackground();
      this.canvas
        .getObjects()
        .find((object) => object.id === "sneaker" || object.id === "areas")
        .center();
    },
    setPolaroid(callBack) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      const articleImg = this.trimImage();
      const img = new Image();
      const _this = this;
      img.src = require(`@/assets/img/frame_1.png`);
      img.onload = function() {
        canvas.width = this.width;
        canvas.height = this.height;
        ctx.beginPath();
        ctx.rect(80, 90, 738, 594);
        ctx.fillStyle = "white";
        ctx.fill();
        ctx.drawImage(img, 0, 0);

        var vCanvas = { width: 738 - 80, height: 500 };
        var scale = Math.min(
          vCanvas.width / articleImg.width,
          vCanvas.height / articleImg.height
        );
        var w = articleImg.width * scale;
        var h = articleImg.height * scale;
        var left = canvas.width / 2 - w / 2;
        var top = canvas.height / 2 - h / 2;
        ctx.drawImage(articleImg, left, top - 65, w, h);
        const logo = new Image();
        logo.src = require(`@/assets/img/frame_contenuti_nologo.png`);
        logo.onload = function() {
          ctx.drawImage(logo, 0, 0);
          var rotatedCanvas = _this.rotateCanvas(canvas);
          return callBack(rotatedCanvas);
        }
      };
    },
    cloneCanvas(oldCanvas) {
      //create a new canvas
      var newCanvas = document.createElement("canvas");
      var context = newCanvas.getContext("2d");

      //set dimensions
      newCanvas.width = oldCanvas.width;
      newCanvas.height = oldCanvas.height;

      //apply the old canvas to the new one
      context.drawImage(oldCanvas, 0, 0);

      //return the new canvas
      return newCanvas;
    },
    rotateCanvas(oldCanvas) {
      //create a new canvas
      var newCanvas = document.createElement("canvas");
      var ctx = newCanvas.getContext("2d");

      //set dimensions
      newCanvas.width = oldCanvas.width;
      newCanvas.height = oldCanvas.height;
      var ox = oldCanvas.width / 2;
      var oy = oldCanvas.height / 2;

      //apply the old canvas to the new one
      ctx.translate(ox, oy);
      ctx.rotate((Math.PI / 180) * -8.9);
      ctx.scale(0.9, 0.9);
      ctx.drawImage(oldCanvas, -ox, -oy);

      ctx.globalCompositeOperation = 'destination-over';
      ctx.rotate((Math.PI / 180) * 8.9);
      ctx.fillStyle = "white";
      ctx.scale(1.25, 1.25);
      ctx.fillRect(-ox, -oy, newCanvas.width, newCanvas.height);

      //return the new canvas
      return newCanvas;
    },
    trimImage() {
      const newCanvas = this.cloneCanvas(this.$refs.c);
      const trimmedImage = trimCanvas(newCanvas);
      return trimmedImage;
    },
    async savePng() {
      this.canvas.discardActiveObject().renderAll();
      switch (process.env.VUE_APP_PICTURE_DOWNLOAD_METHOD) {
        case "download":
          this.setPolaroid(function(data) {
            var downloadLink = document.createElement("a");
            downloadLink.href = data.toDataURL("image/jpeg");
            downloadLink.download = "polaroid.jpg";

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
          break;
        case "upload":
          this.setActiveModal("download");
          // (this.$refs["uploadBox"] ?? { error: true }).error = null;
          // (this.$refs["uploadBox"] ?? { downloadLink: "" }).downloadLink = null;

          this.setPolaroid(async (canvas) => {
            const pictureBlob = await new Promise((resolve) => {
              canvas.toBlob((result) => resolve(result), "image/jpeg");
            });
            const session = this.createUUID();

            const hash = await this.uploadDesign(session, pictureBlob);

            if (!hash) {
              this.error = this.$t('APP.ERROR');
              return;
            }

            const downloadLink = await this.createSessionScratchLink(
              session,
              hash
            );
            this.downloadLink = downloadLink;
            if (!downloadLink) {
              this.error = this.$t('APP.ERROR');
            }
          });
      }
    },
    async uploadDesign(session, pictureBlob) {
      try {
        const formData = new FormData();
        formData.append("file", pictureBlob);
        formData.append("appId", "dreamwall");
        formData.append("fileid", "polaroid.jpg");
        formData.append("session", session);

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/api/upload-temporary-file`,
          {
            method: "POST",
            body: formData,
          }
        );
        console.log(`design upload finished, response.ok is ${response.ok} ${response.status}`);
        return response.ok ? await response.text() : null;
      } catch (error) {
        console.error(`Error uploading design`, error);
        return null;
      }
    },
    async createSessionScratchLink(session, hash) {
      try {
        const createSessionScratchUrl = `${process.env.VUE_APP_LOGIN_API_URL}/api/experience-links`;
        const myHosting = location.origin;
        const response = await fetch(createSessionScratchUrl, {
          method: "POST",
          body: JSON.stringify({
            returnUrl: `${myHosting}/#/download/${session}/${hash}`,
            experience: "DreamWall",
          }),
          headers: {
            "content-type": "application/json",
          },
        });
        if (response.ok) {
          return await response.text();
        }
        console.log(
          "can't create session scratch link, response was " + response.code
        );
        return null;
      } catch (error) {
        console.error(`error creating session scratch link`, error);
        return null;
      }
    },

    createUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },

    // onUploadBoxButtonClicked() {
    //   this.resetHistoryState();
    //   this.canvas.clear();
    //   this.setActiveModal({});
    //   this.$router.push({
    //     name: "Categories",
    //   });
    // },

    onUploadBoxRetry() {
      this.savePng();
    },
  },
};
</script>

<style lang="scss">
.dreamwall {
  height: 100%;
  display: flex;
  &__side {
    position: relative;
    flex-basis: 22.604vw;
  }
  &__main {
    position: relative;
    flex-basis: 77.396vw;
  }
  &__ghost-button {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    border-width: 0;
    background-color: transparent;
    width: 5vw;
    height: 5vw;
  }
  &__download {
    position: absolute;
    z-index: 10;
    bottom: 3.4vh;
    right: 9.6vw;
    padding: 0;
    border: 0;
    display: block;
    background-color: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: 'simplon_monomedium';
    text-transform: uppercase;
    img {
      display: block;
      margin: 0 auto 1vh;
      width: 4vw;
      height: 4vw;
    }
  }
}
</style>
