<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="custom-select__selected" :class="{ 'custom-select__selected--open': open }" @click="open = !open">
      {{ selected.label }}
    </div>
    <div class="custom-select__items" :class="{ 'custom-select__items--hide': !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option.id);
        "
        class="custom-select__item"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.options.filter(option => option.id === this.default)[0]
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  methods: {
    // findDefault() {
    //   if()
    // }
  },
  mounted() {
    this.$emit("input", this.selected.id);
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
    font-family: 'simplon_monoregular';
    position: relative;
    width: 5.94vw;
    text-align: left;
    outline: none;
    height: 4.56vh;
    line-height: 4.56vh;
    font-size: 2.203vh;
    &__selected {
        background-color: #fff;
        border-radius: 6px 0 0 6px;
        box-shadow: 0 0 5px rgb(95 70 70 / 50%);
        color: #000;
        cursor: pointer;
        user-select: none;
        padding-left: 1vw;
        &--open {
            box-shadow: none;
        }
        &:after {
            position: absolute;
            content: "";
            top: calc((4.56vh / 2) - 3px);
            right: 0.5vw;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-color: #000 transparent transparent transparent;
        }
    }
    &__items {
        color: #000;
        border-radius: 6px 0 0 6px;
        overflow: hidden;
        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        box-shadow: 0 0 5px rgb(95 70 70 / 50%);
        &--hide {
            display: none;
        }
    }
    &__item {
        color: #000;
        margin-left: 1vw;
        cursor: pointer;
        user-select: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        &:hover {
          background-color: #f1f1ff;
        }
        &:last-child {
          border-bottom-color: transparent;
        }
    }
}
</style>