import { render, staticRenderFns } from "./ElementsSidebar.vue?vue&type=template&id=61b62c51&scoped=true&"
import script from "./ElementsSidebar.vue?vue&type=script&lang=js&"
export * from "./ElementsSidebar.vue?vue&type=script&lang=js&"
import style0 from "./ElementsSidebar.vue?vue&type=style&index=0&id=61b62c51&lang=scss&scoped=true&"
import style1 from "./ElementsSidebar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b62c51",
  null
  
)

export default component.exports