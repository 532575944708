<template>
    <div 
        class="window" 
        :class="{ 
            'window--opened' : isActive, 
            'window--dragging' : shadowActive,
        }" 
        v-draggable="draggableValue"
    >
        <div class="window__handle" :ref="'handle-id'"></div>
        <div class="window__main" v-if="isActive">
            <button class="window__button" @click="setActiveGroup({})"></button>
            <div class="window__content">
                <slot></slot>
            </div>
        </div>
        <div class="window__icon" v-else>
            <slot name="icon"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Draggable } from 'draggable-vue-directive';

export default {
    name: 'DraggableWindow',
    inheritAttrs: true,
    directives: {
        Draggable,
    },
    props: {
        name: {
            default: '',
            type: String
        }
    },
    computed: {
        ...mapGetters("ui", ["activeGroup"]),
        isActive() {
            return this.activeGroup && this.activeGroup.id === this.name;
        }
    },
    data() {
        return {
            ...mapActions("ui", ["setActiveGroup"]),
            expanded: false,
            initPos: null,
            shadowActive: false,
            draggableValue: {
                boundingElement: undefined,
                handle: undefined,
                onDragStart: undefined,
                onDragEnd: undefined,
            }
        }
    },
    mounted() {
        this.draggableValue.handle = this.$refs["handle-id"];
        this.draggableValue.boundingElement = this.$parent.$refs.c || undefined;
        this.draggableValue.onDragStart = this.onDragStart;
        this.draggableValue.onDragEnd = this.onDragEnd;
    },
    watch: {
        
    },
    methods: {
        onShadow() {
            this.shadowActive = true;
        },
        offShadow() {
            this.shadowActive = false;
        },
        onDragEnd: function(positionDiff, absolutePosition) {
            const leftChange = this.initPos.left - absolutePosition.left;
            const topChange = this.initPos.top - absolutePosition.top
            if((leftChange >= -1 && leftChange <= 1) && (topChange >= -1 && topChange <= 1)) {
                this.setActiveGroup(this.name);
            }
            
            this.offShadow();
        },
        onDragStart: function(positionDiff, absolutePosition) {
            this.initPos = absolutePosition;
            this.onShadow();
        }
    }
}
</script>


<style lang="scss" scoped>
    .window {
        $classWindow: &;
        z-index: 11;
        box-shadow: 0 0 5px rgba(95, 70, 70, 0.5), inset 0 0 0 2px rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        position: static;
        &__icon {
            position: relative;
            width: 4.062vw;
            height: 4.062vw;
            padding: 0.8333vw;
            border: 0.13vw solid #000;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: auto;
            }
        }
        &--dragging {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        }
        &--opened {
            z-index: 12;
            border-radius: 1.4vw;
            padding: 3.88vh 1.09vw 1.94vh;
            #{$classWindow}__handle {
                z-index: 0;
            }
        }
        &__main {
            position: relative;  
        }
        &__handle {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 12;
        }
        &__content {
            position: relative;
        }
        &__button {
            position: absolute;
            display: block;
            background: transparent url("~@/assets/img/ui/icn_resize.svg") no-repeat center center;
            background-size: 0.729vw 0.729vw;
            right: -0.4vw;
            top: -2.666vh;
            width: 1.458vw;
            height: 1.458vw;
            border: 0;
            padding: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }
</style>