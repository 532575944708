<template>
  <div class="ColorBar__Container">
    <section class="ColorBar InterfaceElement">
      <div class="ColorBar__Button ColorBar__Button--left" @click.prevent="slidePrev">
        <img
          class="ColorBar__Arrow ColorBar__Arrow--left"
          src="@/assets/img/ui/arrow_up.svg"
        />
      </div>
      <hooper ref="carousel" :itemsToShow="8" :itemsToSlide="8">
        <hooper-slide v-if="type !== 'texture' && ['bucket', 'strongMarker', 'lightMarker'].includes(activeTool.id)">
          <div
            class="Swatch"
            :style="{
              backgroundImage: `url(${require('@/assets/img/ui/color_wheel.png')})`,
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }"
            :class="{ 'Swatch--active':  activeWheel }"
            @click="onModalOpen('colorWheel')"
          ></div>
        </hooper-slide>
        <hooper-slide 
          v-for="swatch in toolSwatches"
          :key="swatch.id">
          <div
            class="Swatch"
            :style="{
              background: swatch.code
                ? swatch.code
                : `url(${require(`@/assets/img/textures/${swatch.id}.jpg`)})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }"
            :class="{ 'Swatch--active': swatch.isActive && ['bucket', 'strongMarker', 'lightMarker', 'highlighter'].includes(activeTool.id) }"
            @click="onSwatchClick(swatch)"
          />
        </hooper-slide>
      </hooper>
      <div class="ColorBar__Button ColorBar__Button--right" @click.prevent="slideNext">
        <img
          class="ColorBar__Arrow ColorBar__Arrow--right"
          src="@/assets/img/ui/arrow_up.svg"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { fabric } from "fabric";
import { mapGetters, mapActions } from "vuex";
import { setActiveToolOnCanvas } from "@/utils";
import { Hooper, Slide as HooperSlide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: "ColorBar",
  inheritAttrs: true,
  computed: {
    ...mapGetters("ui", ["swatches", "activeTool","activeModal","activeSwatch"]),
    ...mapGetters("canvas", ["canvas"]),
    toolSwatches: function () {
      // `this` points to the vm instance
      return this.swatches.filter(swatch => swatch.type === this.type);
    },
    activeWheel: function () {
      return this.activeSwatch && this.activeSwatch.type !== "color" && this.activeTool.id === "eraser";
    }
  },
  components: {
    Hooper,
    HooperSlide
  },
  props: {
    type: {
      default: 'color',
      type: String
    }
  },
  data() {
    return {
      expanded: true,
      slidePrev() {
        this.$refs.carousel.slidePrev();
      },
      slideNext() {
        this.$refs.carousel.slideNext();
      },
    };
  },
  mounted() {
    // Init color
    // this.setActiveSwatch(this.swatches[0]);
  },
  methods: {
    ...mapActions("ui", ["setActiveSwatch", "setActiveModal"]),
    ...mapActions("canvas", ["setBrushColor", "setBrush"]),
    onSwatchClick(swatch) {
      this.setActiveSwatch(swatch);

      if (swatch.type === "color") {
        this.setBrush(setActiveToolOnCanvas(this.activeTool, this.canvas));
        this.setBrushColor(swatch.code);
      }

      if (swatch.type === "texture") {
        const brushObj = {};
        const img = new Image();
        img.src = require(`@/assets/img/textures/${swatch.id}.jpg`);

        const texturePatternBrush = new fabric.PatternBrush(this.canvas);
        texturePatternBrush.source = img;
        brushObj.type = texturePatternBrush;
        // assign pattern to brush

        this.setBrush(brushObj);
        this.setActiveModal({});
      }
    },
    toggleBar() {
      this.expanded = this.expanded ? false : true;
    },
    onModalOpen(modal) {
      if(['bucket', 'strongMarker', 'lightMarker'].includes(this.activeTool.id))
        this.setActiveModal(modal);
    }
  },
};
</script>

<style lang="scss" scoped>
.ColorBar {
  &__Container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 1.85vh;
  }

  height: 4.6vh;
  width: 23.8vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 100vw;
  position: relative;

  &__Button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2vw;
    position: relative;

    &--left {
      padding: 1vh 0 1vh 0;
    }

    &--right {
      padding: 1vh 0 1vh 0;
    }
  }

  &__list {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    border-radius: 100vw;
  }
  &__swatches {
    display: flex;
    padding: 0 3px;
  }

  &__Arrow {
    width: 25%;

    &--left {
      transform: rotate(-90deg);
    }

    &--right {
      transform: rotate(90deg);
    }
  }

  .Swatch {
    cursor: pointer;
    border-radius: 100%;
    width: 3.6vh;
    height: 3.6vh;
    border: 2px solid #f1f1f1;
    position: relative;
    margin: 1px;
    &--active {
      box-shadow: 0 0 0 1px #999;
    }
  }
}

.hooper {
  height: auto;
  margin-bottom: 0;
  width: 18.8vw;
  height: 4vh;
}
</style>