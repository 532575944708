var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ColorBar__Container"},[_c('section',{staticClass:"ColorBar InterfaceElement"},[_c('div',{staticClass:"ColorBar__Button ColorBar__Button--left",on:{"click":function($event){$event.preventDefault();return _vm.slidePrev($event)}}},[_c('img',{staticClass:"ColorBar__Arrow ColorBar__Arrow--left",attrs:{"src":require("@/assets/img/ui/arrow_up.svg")}})]),_c('hooper',{ref:"carousel",attrs:{"itemsToShow":8,"itemsToSlide":8}},[(_vm.type !== 'texture' && ['bucket', 'strongMarker', 'lightMarker'].includes(_vm.activeTool.id))?_c('hooper-slide',[_c('div',{staticClass:"Swatch",class:{ 'Swatch--active':  _vm.activeWheel },style:({
            backgroundImage: ("url(" + (require('@/assets/img/ui/color_wheel.png')) + ")"),
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }),on:{"click":function($event){return _vm.onModalOpen('colorWheel')}}})]):_vm._e(),_vm._l((_vm.toolSwatches),function(swatch){return _c('hooper-slide',{key:swatch.id},[_c('div',{staticClass:"Swatch",class:{ 'Swatch--active': swatch.isActive && ['bucket', 'strongMarker', 'lightMarker', 'highlighter'].includes(_vm.activeTool.id) },style:({
            background: swatch.code
              ? swatch.code
              : ("url(" + (require(("@/assets/img/textures/" + (swatch.id) + ".jpg"))) + ")"),
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }),on:{"click":function($event){return _vm.onSwatchClick(swatch)}}})])})],2),_c('div',{staticClass:"ColorBar__Button ColorBar__Button--right",on:{"click":function($event){$event.preventDefault();return _vm.slideNext($event)}}},[_c('img',{staticClass:"ColorBar__Arrow ColorBar__Arrow--right",attrs:{"src":require("@/assets/img/ui/arrow_up.svg")}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }