<template>
    <div class="modal__container" v-if="activeModal && activeModal.id === name">
        <div class="modal" :class="{'modal--dragging' : shadowActive }">
            <div class="modal__handle" :ref="'handle-modal'"></div>
            <div class="modal__main">
                <button class="modal__button" @click="onCloseModal"></button>
                <div class="modal__content">
                    <template v-if="activeModal.id === 'colorWheel'">
                        <color-picker v-model="color" class="modal__picker"/>
                        <color-bar type="texture" style="display: none"/>
                    </template>
                    <template v-else-if="activeModal.id === 'reset'">
                        <div class="alert">
                            <h1 class="alert__title" v-html="$t('MODAL.IF_CHANGE')"></h1>
                            <h2 class="alert__subtitle">
                                {{ $t('MODAL.CONTINE') }}
                            </h2>
                            <button class="alert__button alert__button--confirm" @click="onCloseModal($event, param)">{{ $t('MODAL.YES') }}</button>
                            <button class="alert__button" @click="onCloseModal">{{ $t('MODAL.NO') }}</button>
                        </div>
                    </template>
                    <template v-else-if="activeModal.id === 'download'">
                        <slot/>
                    </template>
                    <template v-else>
                        <canvas-handwrite :reset="false"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ColorPicker from 'vue-iro-color-picker';
import { setActiveToolOnCanvas } from "@/utils";
import ColorBar from '@/components/ColorBar.vue';
import CanvasHandwrite from '@/components/CanvasHandwrite.vue';
import { Draggable } from 'draggable-vue-directive';

export default {
    name: 'Modal',
    inheritAttrs: true,
    directives: {
        Draggable,
    },
    props: {
        name: {
            default: '',
            required: true,
            type: String
        },
        param: {
            default: '',
            type: String
        },
    },
    components: {
        ColorBar,
        ColorPicker,
        CanvasHandwrite,
    },
    computed: {
        ...mapGetters("ui", ["activeModal", "activeSwatch", "swatches", "activeTool"]),
        ...mapGetters("canvas", ["canvas"]),
    },
    data() {
        return {
            color: null,
            shadowActive: false,
            draggableValue: {
                boundingElement: undefined,
                handle: undefined,
                onDragStart: undefined,
                onDragEnd: undefined,
            }
        }
    },
    mounted() {
        this.draggableValue.handle = this.$refs["handle-modal"];
        this.draggableValue.boundingElement = this.$parent.$refs.c || undefined;
        this.draggableValue.onDragStart = this.onShadow;
        this.draggableValue.onDragEnd = this.offShadow;
    },
    methods: {
        ...mapActions("ui", ["setActiveModal", "setActiveSwatch"]),
        ...mapActions("canvas", ["setBrushColor", "setBrush"]),
        onCloseModal(event, param) {
            event.preventDefault();
            if(this.activeModal.id === "colorWheel" && this.color) {
                this.setBrush(setActiveToolOnCanvas(this.activeTool, this.canvas));
                this.setBrushColor(this.color);
            } else if(this.activeModal.id === "reset" && param) {
                this.$emit('setArticle', param);
            }
            // if(this.activeModal.id === "download") {
            //     this.$emit('buttonClicked');
            // }
            this.setActiveModal({});
        },
        onShadow() {
            this.shadowActive = true;
        },
        offShadow() {
            this.shadowActive = false;
        },
    }
}
</script>


<style lang="scss" scoped>
    .modal {
        $classWindow: &;
        z-index: 13;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 1rem;
        padding: 3.88vh 1.09vw 1.94vh;
        position: relative;
        box-shadow: 0 0 5px rgba(95, 70, 70, 0.5), inset 0 0 0 2px rgba(255, 255, 255, 1);
        &--dragging {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        }
        &__main {
            position: relative;
            z-index: 1;
        }
        &__content {
            position: relative;
            margin: .5rem;
        }
        &__picker {
            margin: 0 auto 1.09vw auto;
            display: flex;
            justify-content: center;
        }
        &__button {
            position: absolute;
            display: block;
            background: transparent url("~@/assets/img/ui/tools/icn_close.svg") no-repeat center center;
            background-size: 0.729vw 0.729vw;
            right: 0;
            top: -2.666vh;
            width: 1.458vw;
            height: 1.458vw;
            border: 0;
            padding: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
        &__container {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 12;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
        &__handle {
            position: absolute;
            top: 0;
            left: 0;
            right: 2.09vw;
            height: 3.88vh;
            z-index: 0;
        }
    }

    .alert {
        text-align: center;
        font-family: 'simplon_monoregular', sans-serif;
        text-transform: uppercase;
        padding: 6vh 3.51vw 4.25vh 3.51vw;
        &__title {
            font-size: 2.22vh;
            font-family: 'simplon_monomedium', sans-serif;
            margin-bottom: 4.62vh;
        }
        &__subtitle {
            font-size: 2.22vh;
            margin-bottom: 4.62vh;
        }
        &__button {
            margin: 0 0.572vw;
            min-width: 5.98vw;
            height: 4.25vh;
            font-family: 'simplon_monoregular', sans-serif;
            font-size: 2vh;
            text-transform: uppercase;
            background-color: #fff;
            -webkit-appearance: none;
            -moz-appearance: none;
            border-radius: .5vw;
            border: 0.052vw solid black;
            color: #000;
            &--confirm{
                color: #fff;
                background-color: #000;
            }
        }
    }
</style>