<template>
    <div class="quote">
        <label class="quote__label">{{ $t('HANDWRITE.MOST_WANTED') }}</label>
        <div class="quote__prefilled">
            <div class="quote__arrow quote__arrow--left" @click.prevent="slidePrev">
                <img src="@/assets/img/ui/arrow_up.svg"/>
            </div>
            <hooper ref="prefilled" :itemsToShow="1" :itemsToSlide="1">
                <hooper-slide 
                v-for="quote in quotes"
                :key="quote.id">
                    <div
                        class="quote__item"
                        :style="{
                            fontFamily: quote.style
                        }"
                        @click="onPrefilled(quote)"
                    >{{quote.quote.toUpperCase()}}</div>
                </hooper-slide>
            </hooper>
            <div class="quote__arrow quote__arrow--right" @click.prevent="slideNext">
                <img src="@/assets/img/ui/arrow_up.svg"/>
            </div>
        </div>
        <!-- <label class="quote__label">Choose your style</label>
        <ul class="quote__fonts">
            <li 
                v-for="font, index in fonts" 
                :key="index"
                @click="onSetStyle(font.name)"
                class="quote__style"
                :style="{'font-family': font.name}"
                :class="{'quote__style--active' : font.isActive }"
            >
                <span class="quote__tab"> Style {{ index + 1}}</span>
                {{ font.name.toUpperCase() }}
            </li>
        </ul> -->
        <button class="button button--confirm" v-if="!handwrite" @click="handwrite = true" >
            {{ $t('HANDWRITE.WRITE_YOUR') }}
        </button>
        <div class="quote__handwrite" v-show="handwrite">
            <canvas  class="quote__canvas" :class="{'quote__canvas--active' : hideIcon }" width="400" height="300" ref="paper"></canvas>
            <button class="quote__button InterfaceElement Interface__RoundedButton" @click="onRecognize">
                <img :src="require(`@/assets/img/ui/icn_done.svg`)">
            </button>
            <button class="quote__button InterfaceElement Interface__RoundedButton" @click="onClear">
                <img :src="require(`@/assets/img/ui/icn_clear.svg`)">
            </button>
        </div>
        <ul class="quote__list" v-show="filterResult.length">
            <li v-for="item in filterResult" 
                :key="item"
                class="quote__tag"
                @click="onSetQuote(item)"
            >{{item}}</li>
        </ul>
    </div>
</template>

<script>
import {fabric} from 'fabric';
import { mapGetters, mapActions } from "vuex";
import FontFaceObserver from "fontfaceobserver";
import Vue from 'vue';
import { getQuotes } from '@/utils';
import {handwriting} from '@/utils/handwriting.canvas';
import { Hooper, Slide as HooperSlide } from 'hooper';
import 'hooper/dist/hooper.css';

Object.defineProperty(Vue.prototype, '$handwriting', { value: handwriting });

export default {
    name: "CanvasHandwrite",
    data() {
        return {
            quotes: getQuotes(),
            fonts: [
                {
                    name: 'golden_gooseregular',
                    variant: 'uppercase',
                    isActive: true
                },
                {
                    name: 'Kids',
                    variant: 'uppercase',
                    isActive: true
                },
                {
                    name: 'Golden Goose Maker',
                    variant: 'uppercase',
                    isActive: false
                },
                {
                    name: 'Golden Goose',
                    variant: 'uppercase',
                    isActive: false
                },
                {
                    name: 'NotoSans',
                    isActive: true
                },
            ],
            style: null,
            paper: null,
            results: [],
            hideIcon: this.reset,
            handwrite: false,
        };
    },
    components: {
        Hooper,
        HooperSlide
    },
    props: {
        reset: {
            type: Boolean,
            require: true
        }
    },
    mounted() {
        this.$refs.paper.height = window.innerHeight*(1-0.75);
        this.$refs.paper.width = window.innerWidth*(1-0.57);
        this.paper = new this.$handwriting.Canvas(this.$refs.paper, 3);
        this.onSetStyle();
        this.paper.setOptions(
            {
                language: this.$i18n.locale === "cn" ? "zh_cn" : "en",
                numOfReturn: 10,
                numOfWords : 1,
                width: window.innerWidth*(1-0.57),
                height: window.innerHeight*(1-0.75)
            }
        );
        this.paper.setCallBack((data, err) => {
            if(err) throw err;
            else this.results = data;
        });
        this.$refs.paper.addEventListener('touchstart', () => {
            this.hideIcon = true;
        })
    },
    computed: {
        ...mapGetters("canvas", ["canvas"]),
        filterResult() {
            let items = this.results.map(item => item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase());
            items = [...new Set(items)];
            return items.length > 5 ? items.slice(0,5) : items;
        }
    },
    
    methods: {
        ...mapActions("ui", ["setActiveModal"]),
        slidePrev() {
            this.$refs.prefilled.slidePrev();
        },
        slideNext() {
            this.$refs.prefilled.slideNext();
        },
        onSetQuote(quote) {
            const textbox = new fabric.Textbox(quote.toUpperCase(), {
                fontSize: 60,
                editable: false,
                width: 0,
                minWidth: 0,
                left: this.canvas.getWidth()/2,
                top: this.canvas.getHeight()/2,
            });

            this.loadAndUse(this.style, textbox);

            textbox.setControlsVisibility({
                mb: false,
                ml: true,
                mr: true,
                mt: false,
            });

            this.canvas.add(textbox);
            this.canvas.setActiveObject(textbox);
            this.canvas.requestRenderAll();
            this.hideIcon = false;
            this.setActiveModal({});
        },
        onSetStyle(fontId) {
            if(this.$i18n.locale === 'cn') {
                this.style = this.fonts[4].name;
            }else {
                if(fontId) this.fonts = this.fonts.map(font => ({ ...font, isActive: font.name === fontId ? true : false }));
                this.style = this.fonts.length && this.fonts.find(item => item.isActive) ? this.fonts.find(item => item.isActive).name : this.fonts[0].name;
            }
        },
        loadAndUse(font, element) {
            var myfont = new FontFaceObserver(font);
            myfont.load()
                .then(function() {
                    element.set("fontFamily", font);
                    element.set("width", 0);
                }).catch(function() {});
        },
        onPrefilled(data) {
            const font = this.fonts.find(font => font.name === data.style);
            this.onSetStyle(font.id);
            this.onSetQuote(data.quote);
        },
        onRecognize() {
            this.paper.recognize();
        },
        onClear() {
            this.hideIcon = false;
            this.paper.erase();
        },
    }
}
</script>

<style lang="scss" scoped>
.quote {
    $classQuote: &;
    font-family: 'simplon_monoregular';
    width: 43vw;
    &__handwrite {
        position: relative;
    }
    &__button {
        position: absolute;
        border: 0;
        padding: 1vh;
        background-color: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 100%;
        bottom: 2.314vh;
        right: 4.31vw;
        height: 2.34vw;
        width: 2.34vw;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:last-child {
            right: 1.206vw;
        }
    }
    &__label {
        flex: 1 1 100%;
        text-transform: uppercase;
    }
    &__fonts {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin: 1.775vh 0 2.803vh 0;
    }
    &__canvas {
        background-color: #f1f1f1;
        cursor: crosshair;
        width: 43vw;
        height: 25vh;
        border-radius: .5vw;
        background: #f1f1f1 url('~@/assets/img/ui/manina_scrittura.svg') no-repeat center center;
        background-size: 3.90625vw auto;
        &--active {
            background-image: none;
        }
    }
    &__style {
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        position: relative;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        margin-right: 1.041vw;
        font-size: 2.5vh;
        border-radius: .5vw;
        text-align: center;
        height: 7.1296vh;
        flex: 1;
        &--active {
            background-color: #000000;
            color: #ffffff;
            #{$classQuote}__tab {
                background-color: #ffffff;
                color: #000000;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &__tab {
        font-family: 'simplon_monomedium';
        position: absolute;
        background-color: #000000;
        line-height: 3.055vh;
        color: #fff;
        writing-mode: vertical-lr;
        font-size: 0.542vw;
        text-align: center;
        transform: rotate(180deg);
        top: -1px;
        bottom: -1px;
        left: -1px;
    }
    &__prefilled {
        border: 0.052vw solid #cbcbcd;
        border-radius: .5vw;
        margin: 1.775vh 0 2.314vh 0;
        display: flex;
        justify-content: space-between;
    }
    &__list {
        margin-top: 1.851vh;
    }
    &__tag {
        border: 0.052vw solid #b2b2b2;
        border-radius: .5vw;
        padding: 1.296vh;
        display: inline-block;
        font-size: 1.296vh;
        margin: 0.925vh 0.52vw;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 3.074vh;
        line-height: 1;
        padding: 0 1.1203vh;
        height: 6.994vh;
    }
    &__arrow {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.9vw;
        position: relative;
        flex: 0 1 auto;

        &--left {
            padding: 1vh 0 1vh 0;
            img {
                width: 1.041vw;
                transform: rotate(-90deg);
            }
        }

        &--right {
            padding: 1vh 0 1vh 0;
            img {
                width: 1.041vw;
                transform: rotate(90deg);
            }
        }
    }
}

.button {
    margin: 0 0 0 auto;
    min-width: 5.98vw;
    height: 4.25vh;
    font-family: 'simplon_monoregular', sans-serif;
    font-size: 2vh;
    text-transform: uppercase;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 1vw;
    border-radius: .5vw;
    border: 0.052vw solid black;
    display: block;
    color: #000;
    &--confirm{
        color: #fff;
        background-color: #000;
    }
}

.hooper {
    flex: 1 1 auto;
    margin: 0;
    width: 33.854vw;
}
</style>