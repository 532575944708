<template>
  <section class="ElementsSidebar">
    <hooper ref="elementsSlider">
      <template v-if="itemsPerRow > 1">
        <hooper-slide v-for="group, index in itemsGroups" :key="index">
          <div class="ElementsSidebar__group">
            <div class="ElementsSidebar__element" :class="{'ElementsSidebar__element--big' : itemsPerRow <= 4}"
              v-for="element in group"
              :key="element.id">
              <img
                class="ElementsSidebar__image"
                :src="require(`@/assets/img/elements/${element.id}.${element.ext}`)"
                @click="onElementClick"
                draggable="true"
                @dragstart="onDragStart"
              />
            </div>
          </div>
        </hooper-slide>
      </template>
      <template v-else>
        <hooper-slide v-for="element in itemsGroups" :key="element.id">
          <img
            class="ElementsSidebar__element"
            :src="require(`@/assets/img/elements/${element.id}.${element.ext}`)"
            @click="onElementClick"
            draggable="true"
            @dragstart="onDragStart"
          />
        </hooper-slide>
      </template>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
  </section>
</template>

<script>
import {loadToolElements} from "@/utils";
import { Hooper, Slide as HooperSlide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: "ElementsSidebar",
  components: {
    Hooper,
    HooperSlide,
    HooperPagination
  },
  props: {
    itemsPerRow: {
      default: null,
      type: Number
    },
    filterByType: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      elements: loadToolElements(),
      start: false,
    };
  },
  watch: {
    filterByType: function() {
      this.$refs['elementsSlider'].slideTo(0);
    }
  },
  computed: {
    filterItems() {
      if(this.filterByType) {
        return this.elements.filter((element) => element.type === this.filterByType);
      }else{
        return this.elements;
      }
    },
    itemsGroups () {
      return this.itemsPerRow && this.itemsPerRow > 1 ? this.sliceIntoChunks(this.filterItems, this.itemsPerRow) : this.filterItems;
    }
  },
  methods: {
    onDragStart(event) {
      console.log(event);
    },
    onElementClick(event) {
      this.$emit("elementClick", event);
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
          const chunk = arr.slice(i, i + chunkSize);
          res.push(chunk);
      }
      return res;
    }
  },
  mounted() {
    this.start = true;
  }
};
</script>

<style lang="scss" scoped>
.ElementsSidebar {
  $elementsClass: &;
  width: 14.583vw;
  margin-top: 1.85vh;

  &__group {
    width: 14.583vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    #{$elementsClass}__element {
      cursor: pointer;
      width: 33%;
      height: 50%;
      &--big {
        width: 50%;
        height: 50%;
        #{$elementsClass}__image {
          width: 80%;
          height: auto;
          //height: 4vw;
        }
      }
    }
    #{$elementsClass}__image {
      width: 3.645vw;
      height: 3.645vw;
    }
  }
  &__element {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.hooper {
  margin-bottom: 1.94vh;
  height: auto;
  &-pagination {
    bottom: -2.94vh;
  }
  &-indicator {
    width: 0.625vw;
    height: 0.625vw;
    border-radius: 100vw;
    background: #7f7f7f;
    &.is-active, &:hover {
      background: black;
    }
  }
}
</style>