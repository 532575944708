<template>
  <aside class="ToolsSidebar">
    <rounded-button
      v-for="tool in groupTools"
      :key="tool.id"
      class="Interface__RoundedButton"
      :class="{ 'InterfaceElement--active': tool.isActive }"
      @click.native="onToolButtonClick(tool)"
    >
      <template #icon>
        <img :src="require(`@/assets/img/ui/tools/icn_${tool.id}.svg`)" />
      </template>
    </rounded-button>
  </aside>
</template>

<script>
import { fabric } from "fabric";
import { mapGetters, mapActions } from "vuex";
import { setActiveToolOnCanvas, loadActiveToolSwatches } from "@/utils";

import RoundedButton from "@/components/RoundedButton";

export default {
  name: "ToolsSidebar",
  components: {
    RoundedButton,
  },
  props: {
    type: {
      default: 'main',
      type: String
    }
  },
  computed: {
    ...mapGetters("ui", ["tools", "activeTool", "activeSwatch", "swatches"]),
    ...mapGetters("canvas", ["canvas"]),
    groupTools: function() {
      return this.tools.filter(tool => tool.group === this.type);
    }
  },
  mounted() {
    // Init active tool as pencil brush
    // this.setActiveToolOnCanvas(this.tools[0]);
  },
  methods: {
    ...mapActions("ui", ["setActiveTool", "setSwatches", "setActiveSwatch", "setActiveModal"]),
    ...mapActions("canvas", [
      "setCanvasDrawingMode",
      "setBrush",
      "setBrushColor",
    ]),
    onToolButtonClick(tool) {
      console.log("@TOOLBUTTONCLICK", JSON.stringify(tool));
      
      // if(tool.type !== "handwriting") {
        this.setActiveTool(tool.id);
      // }

      if (tool.type === "brush") {
        this.setBrushes(tool);
      }

      if(tool.type === "bucket") {
        this.setBucket(tool);
      }

      if(tool.type === "element") {
        this.setElements(tool);
      }

      if(tool.type === "handwriting") {
        this.setActiveModal("handWriting");
      }
    },
    disabled(tool) {
      return tool.type === "handwriting"
    },
    setBucket(tool){
      const activeToolSwatches = loadActiveToolSwatches(tool);
      const activeSwatchId = this.activeSwatch.id;

      this.setSwatches(activeToolSwatches);
      this.setActiveSwatch(
        activeToolSwatches.find((swatch) => swatch.id === activeSwatchId) ||
          activeToolSwatches[0]
      );
      this.setCanvasDrawingMode(false);
    },
    setBrushes(tool) {
      const activeToolOnCanvas = setActiveToolOnCanvas(tool, this.canvas);
      this.setBrush(activeToolOnCanvas);

      const activeToolSwatches = loadActiveToolSwatches(tool);
      const activeSwatchId = this.activeSwatch.id;

      this.setSwatches(activeToolSwatches);
      this.setActiveSwatch(
        activeToolSwatches.find((swatch) => swatch.id === activeSwatchId) ||
          activeToolSwatches[0]
      );

      if (tool.id !== "smudge" && tool.id !== "eraser") {
        if (this.activeSwatch.type === "texture") {
          const brushObj = {};
          const img = new Image();
          img.src = require(`@/assets/img/textures/${this.activeSwatch.id}.jpg`);

          const texturePatternBrush = new fabric.PatternBrush(this.canvas);
          texturePatternBrush.source = img;
          brushObj.type = texturePatternBrush;
          // assign pattern to brush

          this.setBrush(brushObj);
        }

        const brushColor = activeToolSwatches.find(
          (swatch) => swatch.id === activeSwatchId
        )
          ? activeToolSwatches.find((swatch) => swatch.id === activeSwatchId)
              .code
          : activeToolSwatches[0].code;

        this.setBrushColor(brushColor);
      }
      this.setCanvasDrawingMode(true);
    },
    setElements(){
      this.setCanvasDrawingMode(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.Interface {
  &__RoundedButton {
    box-sizing: border-box;
    width: 3.9vw;
    height: 3.9vw;
    padding: 1vh;
    margin: 0 0.52vw;
  }
}

.ToolsSidebar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3.9vw;
  margin: 0;
}
</style>
