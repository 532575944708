<template>
    <div class="language">
    <custom-select :options="langs"
    :default="$i18n.locale"
    @input="changeLanguage"
    ></custom-select>
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';

export default {
    name: "Language",
    components: {
      CustomSelect
    },
    data() {
        return { 
            langs: [{
              id: 'en',
              label: 'ENG'
            },
            {
              id: 'cn',
              label: '中国人'
            }]
        }
    },
    methods: {
      changeLanguage(event) {
        this.$i18n.locale = event;
      }
    },
}
</script>

<style lang="scss">
    .language {
        position: absolute;
        top: 50%;
        margin-top: -2.08vh;
        right: 0;
    }
</style>